import React from 'react'
import { ArrowRightIcon, ClockIcon, LikeIcon, PlusIcon, UserIcon } from '../icons'

const WhatMakesSection = ({ onClickToTop }) => {
  return (
    <div className='flex flex-col gap-6 text-black justify-center items-center py-16 px-3'>
      <div className='flex items-center gap-3'>
        <div className='bg-[#5a47cc] p-3 rounded-full'>
          <UserIcon
            color={"#f8faf8"}
            w={30}
          />
        </div>
        <div>
          <PlusIcon
            color={"#89839E"}
            w={15}
          />
        </div>
        <div className='bg-green p-3 rounded-full'>
          <ClockIcon
            color={"#00130dd6"}
            w={30}
          />
        </div>
        <div>
          <PlusIcon
            color={"#89839E"}
            w={15}
          />
        </div>
        <div className='bg-[#FFE377] p-3 rounded-full'>
          <LikeIcon
            color={"#5C4E3F"}
            w={30}
          />
        </div>
      </div>
      <div className='text-center flex flex-col gap-3'>
        <div className='text-3xl font-bold'>What Makes Us Different</div>
        <div className='max-w-[700px]'>We don’t wait for action, we create action. Time is priceless, and we do our best to provide you with fast and sustainable connection with the most respected lenders in the industry.</div>
      </div>

      <div>
        <a href='http://trk.alphaeoncredits.com/click'>

          <div
            className='w-[200px] h-[60px] bg-green rounded-md text-black font-bold drop-shadow-lg transition-all flex justify-center'

          >
            <div className='flex gap-3 justify-center items-center opacity-70'>
              <div className=''>
                Get Started
              </div>
              <div>
                <ArrowRightIcon
                  color={"#00130dd6"}
                  w={20}
                />
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

export default WhatMakesSection